<template>
  <b-card title="Explore o espaço 🚀">
    <b-card-text>Esta é a sua segunda página.</b-card-text>
    <b-card-text>Conteúdo da Segunda Página. Hora de ir além das fronteiras.</b-card-text>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
